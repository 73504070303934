.dropdownContainer{
    position: absolute;
    top:45px;
    border-radius: 3px;
    box-shadow: 0 0 5px rgb(0 0 0 / 35%);
    background-color: white;
    max-width: 100vw;
    box-sizing: border-box;
    z-index: 10;
    &.arrowOn:after {
        position: absolute;
        background: white;
        box-shadow: 2px 0px 0px 0px rgb(178 178 178 / 40%);
        content: "";
        height: 15px;
        top: 0px;
        transform: translate(-50%, -50%) rotate(-45deg);
        width: 15px;
        @media (max-width: 700px){
            display: none;
        }
    }

    &.left{
        left: 0px;
        &:after{
            left: 40px;
        }
    }
    &.right{
        right: 0px;
        &:after{
            right: 40px;
        }
    }
}
