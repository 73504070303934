.galleryContainer {
    flex: 1;
    height: 100vh;
    overflow-y: auto;

    @media (max-width: 750px) {
        width: 100%;
        height: auto;
        overflow: unset;
    }

    .itemImage {
        object-fit: cover;
        cursor: pointer;
    }

    .mainImg {
        width: 100%;
        height: 460px;

        @media (max-width: 1000px) {
            height: 250px;
        }

        @media (max-width: 750px) {
            height: 300px;
        }

        @media (max-width: 500px) {
            height: 220px;
        }
    }

    .subImg {
        width: calc(50% - 3px);
        height: 228px;

        @media (max-width: 1000px) {
            width: 100%;
            height: 250px;
        }

        @media (max-width: 750px) {
            width: calc(50% - 3px);
            height: 150px;
        }

        @media (max-width: 500px) {
            display: none;
        }
    }

    .leftImage {
        margin-right: 6px;
    }
}