.modal {
    display: flex;
    position: relative;

    .modalWrapper {
        flex: 1;
        height: 100vh;
        background: white !important;
        display: flex;

        @media (max-width: 750px) {
            overflow-y: auto;
            flex-direction: column;
        }
    }

    .closeWrapepr {
        color: white;
        background: transparent;
        width: fit-content;
        padding: 20px;
        font-family: 700;
        cursor: pointer;
        font-size: 30px;

        @media (max-width: 1000px) {
            padding: 15px 0px 0px 4px;
        }

        @media (max-width: 500px) {
            position: absolute;
            right: 20px;
            top: 10px;
            color: black;
        }
    }
}