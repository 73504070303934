.loginButton{
    position: relative;
    white-space: nowrap;
}
.loginButton img{
    max-height: 35px;
    width: auto;
    border-radius: 50%;
}

