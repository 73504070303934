.SavedHomeItemContainer{
    width:calc((100% - 30px) / 3);
    min-width: 400px;
    margin-bottom: 15px;
    margin-right: 15px;
    padding:15px;
    box-sizing: border-box;
    background: white;
    border-radius: 4px;
    border: 1px solid rgb(241, 241, 244);
    overflow: hidden;
    cursor: pointer;
    &:hover{
        transform: scale(1.02) translate(0px, -1%);
        transition: all 300ms ease-in-out 0s;
        box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px 0px;
    }
    .imgPriceCount{
        position: relative;
        background: #fff;
        border-bottom: 4px solid #006aff;
        box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
        img{
            height: 155px;
            width: 100%;
            object-fit: cover;
        }
        .priceCount{
            padding:5px 10px;
        }
        .saveHomeIcon{
            position: absolute;
            top:10px;
            right:10px;
            font-size: 30px;
            color:#0f7aff;
        }
    }
    .homeDetail{
        padding:25px 0px 10px 0px;
        display: flex;
        flex-wrap: wrap;
        .item{
            display: flex;
            width: calc((100% - 18px) / 3);
            margin-right: 6px;
            box-sizing: border-box;
            line-height: 17px;
            margin-bottom: 15px;
            font-size: 12px;
            .itemIcon{
                color: rgb(0, 106, 255);
                margin-right: 5px;
                font-size: 23px;
            }
        }
    }
    @media (max-width:400px){
        min-width: 100%;
    }
}