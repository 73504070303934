.PagenationContainer{
    display: inline-flex;
    align-items: center;
    .PageBtn{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        background-color: white;
        border-radius: 50%;
        width: 44px;
        height: 44px;
        margin: 3px;
        box-sizing: border-box;
        cursor: pointer;
        &:hover{
            background-color: rgb(219, 219, 219);
        }
        &.active{
            background-color: rgb(242, 250, 255);
            border: 2px solid rgb(0, 106, 255);
        }
        &.disabled{
            color:rgb(189, 189, 189);
            background-color: rgb(249, 249, 251);
            cursor: inherit;
        }
    }
    .PageDot{
        display: inline-flex;
        font-weight: 600;
    }
}