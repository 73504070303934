.lightbox {
    position: fixed;
    color: white;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: rgb(0 0 0 / 100%);
    z-index: 100;
    animation-name: example;
    animation-duration: .2s;

    .boxHeader {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #363636;
        padding: 0px 30px;
        align-items: center;
        margin-bottom: 40px;

        .boxHeaderPhotos {
            padding: 20px 10px;
            color: rgb(1, 115, 245);
            border-bottom: 3px solid rgb(1, 115, 245);
        }

        .actionBtn {
            padding: 3px 10px;
            display: inline-flex;
            align-items: center;
            margin-right: 30px;
            cursor: pointer;
            box-sizing: border-box;

            @media (max-width: 600px) {
                display: none;
            }
        }

        .boxClose {
            margin-left: 20px;
            cursor: pointer;

            &:hover {
                color: white;
                color: rgb(57, 95, 199);
            }
        }
    }

    .lightBoxContainer {
        position: relative;
        text-align: center;
        margin-bottom: 10px;

        .arrowBtn {
            position: absolute;
            top: 48%;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 23px;
            width: 32px;
            height: 45px;
            border: 1px solid gray;
            cursor: pointer;
            background-color: rgb(255 255 255 / 10%);
            z-index: 10;

            polyline {
                stroke: gray;
            }

            &:hover {
                border: 1px solid white;

                polyline {
                    stroke: white;
                }
            }
        }

        .prevBtn {
            left: 30px;
        }

        .nextBtn {
            right: 30px;
        }

        .imageWrapper {
            max-width: 100%;
            height: calc(100vh - 170px);
            position: relative;
            display: inline-block;

            .mainImg {
                height: 100%;
                margin: 0px auto;
                object-fit: cover;
            }

            .countStatus {
                display: inline-block;
                position: absolute;
                top: 10px;
                right: 10px;
                padding: 5px 15px;
                background-color: rgba(27, 27, 27, 0.5);
                color: white;
            }
        }
    }
}

@keyframes example {
    from {
        top: 50%;
        left: 50%;
        width: 0px;
        height: 0px;
    }

    to {
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
    }
}