.accountHeader{
    display: flex;
    justify-content: space-between;
    padding-bottom:0px !important;
    align-items: center;
    .menuContanier{
        flex-wrap: nowrap;
        overflow: auto;
        display: flex;
        .logoLink{
            margin-right: 30px;
            img{
                max-height: 35px;
                width: auto;
            }
        }
        a{
            color:inherit;
            text-decoration: none;
            margin-right: 20px;
        }
        .item{
            padding: 8px 0px;
            cursor: pointer;
            font-size: 15px;
            white-space: nowrap;
            &.active{
                color:rgb(0, 106, 255);
                border-bottom: 3px solid rgb(0, 106, 255);
                font-weight: 600;
            }
        }
        margin-right: 10px;
    }
}
