.grayOutlineBtn{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(180, 180, 180);
    cursor: pointer;
}
.grayOutlineBtn:hover{
    background-color: #f4f7ff;
    border-color: rgb(0, 79, 190);
}
.filterBtn{
    padding: 3px 30px;
    color:rgb(0, 79, 190);
    font-size: 12px;
    white-space: nowrap;
    @media (max-width: 350px){
        padding: 3px 15px;
    }
}
.filterMain{
    display: flex;
    align-items: center;
    @media (max-width: 510px){
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;
    }
}

.filterOne{
    display: inline-block;
    position: relative;
    margin-right: 15px;
    &.responsiveNone{
        @media (max-width: 1070px){
            display: none;
        }
    }
    @media (max-width: 510px){
        margin-right: 0px;
    }
}
.dropdownFooter{
    padding: 10px 15px;
    background-color: rgb(0, 106, 255);
    text-align: right;
}

.filterItemTitle{
    width: 100%;
    font-weight: bold;
    margin-bottom: 8px;
}
